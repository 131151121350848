const events = [
  {
    day: 1,
    name: "Opening Ceremonies",
    type: "Main Event",
    location: "Discord/Zoom",
    startTime: "7:00pm",
    endTime: "8:00pm",
  },
  {
    day: 1,
    name: "Huawei Event",
    type: "Workshop",
    location: "Discord/Zoom",
    startTime: "8:00pm",
    endTime: "8:45pm",
    description:
      "Get to know Huawei and meet the team! We will be going over potential career prospects, hosting a Q&A session and doing a product raffle! Join us for a chance to win great prizes and learn more about Huawei.",
  },
  {
    day: 1,
    name: "Team Formation",
    type: "Main Event",
    location: "Discord",
    startTime: "9:00pm",
    endTime: "10:00pm",
  },
  {
    day: 1,
    name: "Hacking Begins",
    type: "Main Event",
    startTime: "9:00pm",
    endTime: null,
  },
  {
    day: 1,
    name: "Web Dev Workshop: Create a Portfolio with HTML/CSS",
    type: "Workshop",
    location: "Discord/Zoom",
    startTime: "10:00pm",
    endTime: "11:00pm",
    description:
      "Looking to make a personal website or portfolio but don't know where to start? Join us for this workshop on creating a portfolio with HTML/CSS! You'll learn some basic HTML/CSS from a walkthrough with us on how to create a portfolio webpage that you can push right to deployment or expand on after. Before the workshop, be sure to sign up for Glitch or link it to your Github account (www.glitch.com).",
  },
  {
    day: 1,
    name: "Mini Game: Among Us",
    type: "Activity",
    location: "Discord",
    startTime: "11:00pm",
    endTime: "12:00am",
  },
  {
    day: 2,
    name: "Midnight Activity 1: Geoguessr",
    type: "Activity",
    location: "Discord/Zoom",
    startTime: "12:00am",
    endTime: "1:00am",
    description:
      "Put your geographic knowledge to the test in our Geoguessr challenge! You will be dropped in five random locations on the globe, and your task is to guess where on the map you think you are. The closer your guess is to true location, the more points you will earn! There is no need to pay for a subscription as non-paying members get one free game every 24 hours. A prize will be awarded to whoever has the highest score 12 hours after the challenge has been posted!",
  },
  {
    day: 2,
    name: "Wellness Hour: Guided Meditation",
    type: "Activity",
    location: "Discord",
    startTime: "1:00am",
    endTime: "2:00am",
    description:
      "Is staring at the computer screen starting to drive you crazy? Is the debugger making no sense at all? Give your brain a breather, and join us for some guided meditation! ",
  },
  {
    day: 2,
    name: "Mini Games",
    type: "Activity",
    location: "Discord",
    startTime: "2:00am",
    endTime: "7:00am",
    description:
      "Come join us for some fun rounds of mini games! We will be playing skribbl, among us, enigma, wikipedia races, … or come suggest your favourite game! These sessions will be held at various times throughout the night, for our night owls and hackers in different time zones!",
  },

  {
    day: 2,
    name: "Coffee Hour",
    type: "Activity",
    location: "Discord",
    startTime: "8:00am",
    endTime: "9:00am",
  },
  {
    day: 2,
    name: "Yoga ",
    type: "Activity",
    location: "Discord",
    startTime: "8:30am",
    endTime: "9:30am",
  },
  {
    day: 2,
    name: "Scavenger Hunt",
    type: "Activity",
    location: "Discord",
    startTime: "9:45am",
    endTime: "10:30am",
  },
  {
    day: 2,
    name: "FDM Group Workshop",
    type: "Workshop",
    location: "Discord/Zoom",
    startTime: "10:30am",
    endTime: "11:00am",
    description:
      "We will cover the basics of functional programming, diving into its origins and key features. By comparing it with the more “mainstream” approach of imperative programming, we will try to understand the reasons behind functional programming’s recent revival.",
  },
  {
    day: 2,
    name: "Big Bang Workshop",
    type: "Workshop",
    location: "Discord/Zoom",
    startTime: "11:00am",
    endTime: "11:30am",
    description:
      "In a rapidly evolving IT world, the probability that what you will learn in your university courses will become outdated by the time you enter the workforce is high. Keeping in touch with the industry is a major competitive advantage for your career. In this workshop, we will be taking an honest look at the realities of working part-time throughout your studies, how to achieve work-study balance, and how to seek out the right opportunities to propel your tech career.",
  },
  {
    day: 2,
    name: "Business-In-A-Box Workshop",
    type: "Workshop",
    location: "Discord/Zoom",
    startTime: "11:30am",
    endTime: "12:30pm",
    description:
      "What does a hacker do exactly? Learn five simple strategies to achieve just about any goal or project. Join the workshop to learn more about the Business-in-a-Box 2021 AI Chatbot Challenge. The team that presents the best solution will win our $3,000 prize.",
  },
  {
    day: 2,
    name: "Speed Networking",
    type: "Activity",
    location: "Discord/Zoom",
    startTime: "1:00pm",
    endTime: "3:00pm",
  },
  {
    day: 2,
    name: "Logic Puzzles Competition",
    type: "Activity",
    location: "Discord/Zoom",
    startTime: "2:00pm",
    endTime: "3:00pm",
    description:
      "Bring your friends to solve some of the toughest and funniest riddles in teams of four. There will be a wide variety of puzzles requiring you to prove your creativity! The brightest team will win a prize.",
  },
  {
    day: 2,
    name: "Design Workshop",
    type: "Workshop",
    location: "Discord/Zoom",
    startTime: "3:00pm",
    endTime: "4:00pm",
    description: "Learn how to create a simple UI mockup with Figma!",
  },
  {
    day: 2,
    name: "MLH Powerpoint Karaoke",
    type: "Activity",
    location: "Discord/Zoom",
    startTime: "4:00pm",
    endTime: "5:00pm",
  },
  {
    day: 2,
    name: "Baking/Cookies!",
    type: "Activity",
    location: "Discord",
    startTime: "5:00pm",
    endTime: "6:00pm",
    description:
      "Take a break from hacking for a bake-along-with-us chocolate chip cookie making session! Or if those aren’t to your taste, make one of your own favorite recipes! We’ll be sharing pictures of our baking results once they’re finished. Alternative recipes for gluten-free and vegan cookies will be made available.",
  },
  {
    day: 2,
    name: "Dinner",
    type: "Activity",
    location: "Discord/Zoom",
    startTime: "6:00pm",
    endTime: "7:00pm",
    description: "Come have dinner with us! Zoom cafeteria vibes",
  },
  {
    day: 2,
    name: "Trivia",
    type: "Activity",
    location: "Discord",
    startTime: "7:15pm",
    endTime: "8:00pm",
    description:
      "Join us for a trivia challenge, where no topic is off-limits! A prize will be awarded to the bright hacker that takes home the W!",
  },
  {
    day: 2,
    name: "Movie Party",
    type: "Activity",
    location: "Discord/Zoom",
    startTime: "8:00pm",
    endTime: "10:00pm",
    description:
      "Need a breather after a long day of coding? Join us for a special movie night starting at 8pm on Saturday night! Those with a netflix subscription can join a netflix party to watch together. Those without a netflix subscription are welcome to join us to watch a movie on YouTube. Movie choices TBD!",
  },
  {
    day: 3,
    name: "Midnight Activity 2: Geoguessr",
    type: "Activity",
    location: "Discord/Zoom",
    startTime: "12:00am",
    endTime: "1:00am",
    description:
      "Put your geographic knowledge to the test in our Geoguessr challenge! You will be dropped in five random locations on the globe, and your task is to guess where on the map you think you are. The closer your guess is to true location, the more points you will earn! There is no need to pay for a subscription as non-paying members get one free game every 24 hours. A prize will be awarded to whoever has the highest score 12 hours after the challenge has been posted!",
  },
  {
    day: 3,
    name: "Wellness Hour: Guided Meditation",
    type: "Activity",
    location: "Discord",
    startTime: "1:00am",
    endTime: "2:00am",
    description:
      "Is staring at the computer screen starting to drive you crazy? Is the debugger making no sense at all? Give your brain a breather, and join us for some guided meditation! ",
  },
  {
    day: 3,
    name: "Mini Games",
    type: "Activity",
    location: "Discord",
    startTime: "2:00am",
    endTime: "7:00am",
    description:
      "Come join us for some fun rounds of mini games! We will be playing skribbl, among us, enigma, wikipedia races, … or come suggest your favourite game! These sessions will be held at various times throughout the night, for our night owls and hackers in different time zones!",
  },
  {
    day: 3,
    name: "DevPost Submission Deadline",
    type: "Main Event",
    location: "Devpost",
    startTime: "7:30am",
  },
  {
    day: 3,
    name: "Hacking Ends",
    type: "Main Event",
    startTime: "9:00am",
    endTime: null,
  },
  {
    day: 3,
    name: "Demo",
    type: "Main Event",
    location: "Discord",
    startTime: "11:00am",
    endTime: "1:00pm",
  },
  {
    day: 3,
    name: "Novartis Keynote",
    type: "Main Event",
    location: "Discord/Zoom",
    startTime: "1:15pm",
    endTime: "1:45pm",
  },
  {
    day: 3,
    name: "Closing Ceremonies",
    type: "Main Event",
    location: "Discord/Zoom",
    startTime: "1:45pm",
    endTime: "2:45pm",
  },
]

export default events
